import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import SectionBlack from '../components/SectionBlack';
import SectionBlackTitle from '../components/SectionBlackTitle';
import SectionTitle from '../components/SectionTitle';
import Layout7h from '../components/Layout7h';
import Seo from '../components/Seo';
import ShareButton from '../components/ShareButton';
import ModalWindow from '../components/ModalWindow';
import MemberLink from '../components/MemberLink';

import * as svgPath from '../images/svgPath';
import * as Styles from '../styles/styles';
import LinkBtn from '../components/LinkBtn';
import Section7thBlack from '../components/Section7thBlack';

const IndexPage: React.FC = ({ data }) => {
  return (
    <Layout7h
      style={css`
        html {
          background: #12100d;
        }
      `}
    >
      <Seo />
      <section className="mainVisual text-center">
        <StaticImage
          src="../images/7th/key_visual.jpg"
          width={1440}
          quality={100}
          alt="Life Like a Live!7(えるすりー)"
          placeholder="none"
          loading="eager"
          formats={['webp', 'auto']}
        />
      </section>

      <SectionBlack isWhite={true} addClass={'about_sec'} setCss={Styles.aboutSec}>
        <SectionBlackTitle enText={'ABOUT'} jaText={'イベントについて'} textWhite={false} />
        <p className="text-center" css={Styles.aboutText}>
          世界最大級の
          <br className="sp_visible" />
          バーチャルアイドルフェス
          <br />
          <br />
          <strong>「Life Like a live!」第7回！​</strong>
          <br />
          <br />
          今回のモチーフは天界！
          <br className="sp_visible" />
          人を愛し導く天使との契約により、
          <br className="sp_visible" />
          不思議な力を手に入れたアイドルたち！
          <br />
          神々しく輝き祝福の光射す清廉なライブ！？
          <br className="sp_visible" />
          アイドルたちの愛が貴方を眩く照らす…！
        </p>

        <StaticImage
          src="../images/7th/f_logo.png"
          width={284}
          alt="Life Like a Live!"
          placeholder="none"
          loading="eager"
        />
      </SectionBlack>

      <Section7thBlack addClass={'event_sec'}>
        <SectionTitle enText={'OUTLINE'} jaText={'イベント概要'} textWhite={true} />

        <dl className="c-white" css={Styles.scheduleDl}>
          <dt>
            <span>開催日程</span>
          </dt>
          <dd>2024年3月22日(金) ～ 3月24日(日)</dd>
        </dl>

        <dl className="c-white" css={Styles.scheduleDl}>
          <dt>
            <span>開催場所</span>
          </dt>
          <dd>オンライン（Z-aN、YouTube）</dd>
        </dl>

        <h3 className="c-white" css={Styles.middleTitle}>
          公演概要
        </h3>

        <div css={[Styles.eventSchedule, Styles.mw1024]}>
          <div css={Styles.timetableImage}>
            <StaticImage
              src="../images/7th/lll7_time_table.jpg"
              width={960}
              alt="Life Like a Live!7 タイムテーブル"
              placeholder="none"
              loading="eager"
            />
          </div>

          <h4 className="text-center section_title">■有料公演​</h4>
          <ul>
            <li>
              <dl>
                <dt>
                  2024年3月22日(金)
                  <br />
                  第一公演（夜公演）
                  <br className="sp_visible" />
                  ＜開演19：30 (開場19：00)＞
                </dt>
                <dd className="member_image">
                  <a href="https://www.zan-live.com/live/detail/10403" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_vol1_vd_fix.jpg"
                      width={960}
                      alt="Life Like a Live!7 第一公演 2024年3月22日(金) / 開演19:30"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>徳井Ｖ青空</li>
                      <li>.LIVE(カルロ・ピノ / ヤマト イオリ)</li>
                      <li>ななしいんく(季咲あんこ / 蛇宵ティア)</li>
                      <li>まりなす</li>
                      <li>Re:AcT(九楽ライ)</li>
                      <li>REGALILIA(江波キョウカ / 鬼多見アユム)</li>
                      <li>LiLYPSE(暁おぼろ)</li>
                    </ul>
                    <div className="flex-cell-caution">
                      ※
                      <ul className="flex-cell">
                        アイデス
                        <li>えのぐ</li>
                        <li>音ノ癒いろは</li>
                        <li>
                          けものフレンズVぷろじぇくと(フンボルトペンギン、シマハイイロギツネ、カラカル、ジャングルキャット、ウサギコウモリ)
                        </li>
                        <li>GEMS COMPANY</li>
                        <li>白雪みしろ</li>
                        <li>
                          .LIVE(SIRO / 花京院ちえり / 神楽すず /  もこ田めめめ / 七星みりり /  リクム /
                          ルルン・ルルリカ)
                        </li>
                        <li>ななしいんく(家入ポポ / 島村シャルロット / 瀬島るい)</li>
                        <li>Palette Project(暁月クララ / 香鳴ハノン / 常磐カナメ / 七海ロナ / 藤宮コトハ)</li>
                        <li>ぶいごま(V後藤真希)</li>
                        <li>夢瞳カナウ</li>
                        <li>Re:AcT(天川はの / 稀羽すう / 獅子神レオナ / 月紫アリア)</li>
                      </ul>
                      はオープニングライブのみの出演となります。
                    </div>
                  </div>
                </dd>
              </dl>
            </li>

            <li>
              <dl>
                <dt>
                  2024年3月23日(土)
                  <br />
                  第二公演（昼公演）
                  <br className="sp_visible" />
                  ＜開演13：00 (開場12：30)＞
                </dt>
                <dd className="member_image">
                  <a href="https://www.zan-live.com/live/detail/10403" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_vol2_vd_fix.jpg"
                      width={960}
                      alt="Life Like a Live!7 第二公演 2024年3月23日(土) / 開演13:00"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>えのぐ</li>
                      <li>GEMS COMPANY</li>
                      <li>Sputrip(常磐カナメ / 暁月クララ / 香鳴ハノン)</li>
                      <li>.LIVE(七星みりり / リクム / ルルン・ルルリカ)</li>
                      <li>ななしいんく(家入ポポ)</li>
                      <li>夢瞳カナウ</li>
                      <li>Re:AcT(天川はの)</li>
                    </ul>
                  </div>
                </dd>
              </dl>
            </li>

            <li>
              <dl>
                <dt>
                  2024年3月23日(土)
                  <br />
                  第三公演（夜公演）
                  <br className="sp_visible" />
                  ＜開演19：30 (開場19：00)＞
                </dt>
                <dd className="member_image">
                  <a href="https://www.zan-live.com/live/detail/10403" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_vol3_vd_fix.jpg"
                      width={960}
                      alt="Life Like a Live!7 第三公演 2024年3月23日(土) / 開演19:30"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>アイデス</li>
                      <li>音ノ癒いろは</li>
                      <li>GEMS COMPANY（音羽雫 / 奈日抽ねね / 一文字マヤ / 有栖川レイカ / 長谷みこと）</li>
                      <li>白雪みしろ</li>
                      <li>ななしいんく(瀬島るい)</li>
                      <li>ぶいごま(V後藤真希)</li>
                      <li>まりなす</li>
                      <li>Re:AcT(獅子神レオナ)</li>
                      <li>LiLYPSE</li>
                      <li>RouteHeart(藤宮コトハ / 七海ロナ)</li>
                    </ul>
                  </div>
                </dd>
              </dl>
            </li>

            <li>
              <dl>
                <dt>
                  2024年3月24日(日)
                  <br />
                  第四公演（昼公演）
                  <br className="sp_visible" />
                  ＜開演13：00 (開場12：30)＞
                </dt>
                <dd className="member_image">
                  <a href="https://www.zan-live.com/live/detail/10403" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_vol4_vd_fix.jpg"
                      width={960}
                      alt="Life Like a Live!7 第四公演 2024年3月24日(日) / 開演13:00"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>
                        けものフレンズVぷろじぇくと(フンボルトペンギン、シマハイイロギツネ、カラカル、ジャングルキャット、ウサギコウモリ)
                      </li>
                      <li>GEMS COMPANY（水科葵 / 長谷みこと）</li>
                      <li>白雪みしろ</li>
                      <li>.LIVE(SIRO / もこ田めめめ)</li>
                      <li>ななしいんく(島村シャルロット)</li>
                      <li>まりなす</li>
                      <li>Re:AcT(稀羽すう / 月紫アリア)</li>
                      <li>LiLYPSE(暁みかど)</li>
                    </ul>
                  </div>
                </dd>
              </dl>
            </li>

            <li>
              <dl>
                <dt>
                  2024年3月24日(日)
                  <br />
                  第五公演（夜公演）
                  <br className="sp_visible" />
                  ＜開演19：30 (開場19：00)＞
                </dt>
                <dd className="member_image">
                  <a href="https://www.zan-live.com/live/detail/10403" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_vol5_vd_fix.jpg"
                      width={960}
                      alt="Life Like a Live!7 第五公演 2024年3月24日(日) / 開演19:30"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>アイデス</li>
                      <li>えのぐ</li>
                      <li>GEMS COMPANY</li>
                      <li>.LIVE(神楽すず / 花京院ちえり)</li>
                      <li>Palette Project</li>
                      <li>まりなす</li>
                      <li>LiLYPSE </li>
                    </ul>

                    <div className="flex-cell-caution">
                      ※
                      <ul className="flex-cell">
                        <li>音ノ癒いろは</li>
                        <li>
                           けものフレンズVぷろじぇくと(フンボルトペンギン、シマハイイロギツネ、カラカル、ジャングルキャット、ウサギコウモリ)
                        </li>
                        <li>白雪みしろ</li>
                        <li>
                          .LIVE(SIRO / カルロ・ピノ もこ田めめめ / ヤマト イオリ / 七星みりり / リクム /
                          ルルン・ルルリカ)
                        </li>
                        <li>ななしいんく（家入ポポ / 季咲あんこ / 島村シャルロット / 瀬島るい / 蛇宵ティア）</li>
                        <li>ぶいごま(V後藤真希)</li>
                        <li>夢瞳カナウ</li>
                        <li>Re:AcT(天川はの / 稀羽すう / 九楽ライ / 獅子神レオナ / 月紫アリア)</li>
                      </ul>
                      はエンディングライブのみの出演となります。
                    </div>
                  </div>
                </dd>
              </dl>
            </li>
          </ul>

          <h4 className="text-center section_title">■無料公演</h4>
          <ul>
            <li>
              <dl>
                <dt>
                  YouTube 2024年3月22日(金)
                  <br />
                  開演15：30　
                  <br />
                  予習配信
                  <br />
                  <br />
                  YouTube 2024年3月23日(土)
                  <br />
                  開場15：30　開演16：00　
                  <br />
                  えるすりーパラダイス えるすりーパラダイス ～お団子編～
                  <br />
                  Life Like a Live!7×Sakura Virtual Fes 2024
                </dt>
                <dd className="member_image">
                  <a href="https://www.youtube.com/@lifelikealive230" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_paradise_1.jpg"
                      width={960}
                      alt="Life Like a Live!7 えるすりーパラダイス えるすりーパラダイス ～お団子編～ 2024年3月22日(金) / 開演16:00"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                {/* <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>GEMS COMPANY(奈日抽ねね、長谷みこと)</li>
                      <li>Palette Project(暁月クララ)</li>
                      <li>Re:AcT(月紫アリア、天川はの)</li>
                    </ul>
                  </div>
                </dd> */}
              </dl>
            </li>

            <li>
              <dl>
                <dt>
                  YouTube 2024年3月23日(土)
                  <br />
                  開場17：45　開演18：00
                  <br />
                  ネクストえるすりー
                </dt>
                <dd className="member_image">
                  <a href="https://www.youtube.com/@lifelikealive230" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_next.jpg"
                      width={960}
                      alt="Life Like a Live!7 ネクストえるすりー 2024年3月23日(金) / 開演18:00"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                {/* <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>恋犬ちわこ</li>
                      <li>楠名はる</li>
                      <li>Nanoha。</li>
                      <li>Palette Project(江波キョウカ)</li>
                      <li>まりなす</li>
                    </ul>
                  </div>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">動画出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>夜世生宵</li>
                    </ul>
                  </div>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">MC：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>まりなす</li>
                      <li>Palette Project(江波キョウカ)</li>
                    </ul>
                  </div>
                </dd> */}
              </dl>
            </li>

            <li>
              <dl>
                <dt>
                  YouTube 2024年3月24日(日)
                  <br />
                  開場15：30　開演16：00
                  <br />
                  えるすりーパラダイス ～お花見編～
                  <br />
                  Life Like a Live!7×Sakura Virtual Fes 2024
                </dt>
                <dd className="member_image">
                  <a href="https://www.youtube.com/@lifelikealive230" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_paradise_2.jpg"
                      width={960}
                      alt="Life Like a Live!7 えるすりーパラダイス ～お花見編～ 2024年3月24日(日) / 開演16:00"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                {/* <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>めたしっぷ(桜葉ハグ、黄花はてな)</li>
                      <li>GEMS COMPANY(音羽雫、小瀬戸らむ)</li>
                      <li>Palette Project(江波キョウカ)</li>
                      <li>まりなす（鈴鳴すばる）</li>
                    </ul>
                  </div>
                </dd> */}
              </dl>
            </li>

            <li>
              <dl>
                <dt>
                  YouTube　2024年3月24日(日)
                  <br />
                  開場17：30　開演18：00
                  <br />
                  まりなすと行く！Sakura Virtual Fes 2024
                </dt>
                <dd className="member_image">
                  <a href="https://www.youtube.com/@lifelikealive230" target="_blank" rel="noopener noreferrer">
                    <StaticImage
                      src="../images/7th/lll7_sakura_fes.jpg"
                      width={960}
                      alt="Life Like a Live!7 Coming soon 2024年3月24日 / 開演18:00"
                      placeholder="none"
                      loading="eager"
                    />
                  </a>
                </dd>
                {/* <dd className="text-left dl-flex">
                  <p className="flex-heading">出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>うてぃやん</li>
                      <li>愛音クレナ</li>
                      <li>ねえさん。</li>
                      <li>えのぐ</li>
                    </ul>
                  </div>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">動画出演：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>コザ</li>
                    </ul>
                  </div>
                </dd>
                <dd className="text-left dl-flex">
                  <p className="flex-heading">MC：</p>
                  <div>
                    <ul className="flex-cell">
                      <li>えのぐ</li>
                    </ul>
                  </div>
                </dd> */}
              </dl>
            </li>
          </ul>
        </div>

        <h3 className="c-white" css={Styles.middleTitle}>
          チケット（有料公演のみ）​
        </h3>
        <div css={[Styles.eventSchedule, Styles.mw1024]}>
          {/* <dl css={[Styles.scheduleDl, { marginBottom: '35px' }]}>
            <dt>＜販売期間＞</dt>
            <dd>
              2023年10月14日(土)19:00-
              <br className="sp_visible" />
              2023年11月26日(日)12:00まで
            </dd>
          </dl> */}
          <a href="https://www.zan-live.com/live/detail/10403" target="_blank" rel="noopener noreferrer">
            <StaticImage
              src="../images/7th/lll7_valentine_ticket.jpg"
              width={960}
              alt="Life Like a Live!7 Coming soon 2024年3月24日 / 開演18:00"
              placeholder="none"
              loading="eager"
            />
          </a>
          <br />
          <ul>
            <li css={[{ marginTop: '35px' }]}>
              <dl>
                <dt className="text-left">
                  <p>◆単体公演チケット（5種）：各4,500円</p>
                  <p>◆通しチケット：20,000円（2,500円引き）</p>
                  <p>◆プレミア通しチケット（デジタル特典付き）：30,000円</p>
                </dt>

                <dd className="text-left">
                  <h4 className="benefits-title">
                    <strong>＜プレチケ特典＞​</strong>
                  </h4>
                  <ul className="benefits-list">
                    <li>
                      <strong>①</strong>えるすりー7特製チケット画像（ヘッダーサイズ）【1種】
                      <br />
                      描きおろしイラストを使用した特製ヘッダー。チケット購入時にプレゼント（pngデータ）
                      <br />
                      設定してね！
                    </li>
                    <li>
                      <strong>②</strong>
                      天使の慈愛セリフボイス【種類数：出演者分　※オーディションイベントからの参加者は含まれておりません。】
                      <br />
                      いつも応援してくれるファンのみんなへ、天使みたいな推しからのお礼の慈愛ボイス。（最低約30秒～最大約60秒）
                    </li>
                    <li>
                      <strong>③</strong>天使すぎるPC壁紙（キービジュアル）【1種】
                      <br />
                      キービジュアルを使用したPCサイズの壁紙画像。
                    </li>
                    <li>
                      <strong>④</strong>
                      推しからの特製祝福スマホ壁紙（個別）【種類数：出演者分　　※オーディションイベントからの参加者は含まれておりません。】
                      <br />
                      全キャストの書下ろしビジュアルを使用したスマホサイズの特製壁紙画像。推しからの祝福を受けた気持ちになれる。
                    </li>
                    <li>
                      <strong>⑤</strong>オープニング&エンディング アーカイブ映像【2種】
                      <br />
                      第一公演のオープニングライブ、第五公演のエンディングライブ映像データ。
                    </li>
                    <li>
                      <strong>⑥</strong>ストーリー動画まとめ【種類数未定】
                      <br />
                      ライブ中に流れるストーリー動画データ。
                    </li>
                    <li>
                      <strong>⑦</strong>祝福のえるすりーメモリアルブック【1種】
                      <br />
                      イベント終了後に、キャストにアンケートをとって制作。ライブのセトリや、それぞれの思い出。
                      <br />
                      裏話や設定資料なども盛り込んだメモリアルブック。
                    </li>
                    <li>
                      <strong>⑧</strong>気まぐれ天使の楽屋トーク【種類数未定】
                      <br />
                      えるすりー7当日、楽屋に待機している気まぐれな天使（アイドル）たちが自由に収録したボイス。どんな内容かは受け取ってみてのお楽しみ。
                    </li>
                  </ul>
                  <p className="caution">
                    ※2024年6月末までにZ-aN登録メールアドレス宛に主催者（Life Like a
                    live!運営メールアドレス）よりメールにて特典を送付します。
                  </p>
                </dd>
              </dl>
            </li>
          </ul>
        </div>

        <h3 className="c-white" css={Styles.middleTitle}>
          販売サイト
        </h3>
        <ul css={Styles.buttonTop}>
          <li>
            <LinkBtn linkto="https://www.zan-live.com/live/detail/10403" imageIn={true}>
              販売サイトはこちら
              <div className="image-wrap">
                <StaticImage
                  src="../../images/3rd/z-an_logo.png"
                  height={18}
                  alt="Z-an Logo"
                  placeholder="none"
                  loading="eager"
                />
              </div>
            </LinkBtn>
          </li>
        </ul>
      </Section7thBlack>

      <Section7thBlack addClass={'other_sec'}>
        <SectionTitle enText={'OTHER'} jaText={'コラボイベント情報紹介'} textWhite />

        <div css={Styles.otherSecStyle}>
          <div className="other-wrap banner-wrap text-center">
            <dl>
              <dt>
                <strong>
                  【Life Like a Live!7カプセル】
                  <br />
                  2024年3月20日(水) 21:00 ~ <br className="sp_visible" />
                  2024年4月7日(日) 23:59
                </strong>
              </dt>
              <dd>
                <a href="https://www.zan-live.com/capsule/detail/10032" target="_blank" rel="noopener noreferrer">
                  <StaticImage
                    src="../images/7th/lll7_wh_zan_capsule.jpg"
                    width={960}
                    alt="Life Like a Live!7 Z-aN Capsule"
                    placeholder="none"
                    loading="eager"
                  />
                </a>
              </dd>
            </dl>
          </div>

          <div className="other-wrap banner-wrap text-center">
            <dl>
              <dt>
                <strong>【BOOTHショップ】</strong>
              </dt>
              <dd>
                <StaticImage
                  src="../images/7th/lll7_valentine_goods.jpg"
                  width={960}
                  alt="Life Like a Live!7 バレンタイングッズ"
                  placeholder="none"
                  loading="eager"
                />
              </dd>

              <dt>
                <strong>
                BOOTHにてホワイトデーグッズ販売中！
                </strong>
              </dt>
              <dd>
                <a href="https://lifelikealive.booth.pm/" target="_blank" rel="noopener noreferrer">
                  <StaticImage
                    src="../images/7th/lll7_wh_goods.jpg"
                    width={960}
                    alt="Life Like a Live!7 ホワイトデーグッズセット"
                    placeholder="none"
                    loading="eager"
                  />
                </a>
              </dd>

              <dt>
                <strong>
                  ホワイトデーフラスタ販売中！
                  <br />
                  2024年3月3日13:00 〜<br className="sp_visible" />
                  2024年3月13日13:00
                </strong>
              </dt>
              <dd>
                {/* <a href="https://lllofficial.base.shop/" target="_blank" rel="noopener noreferrer">
                  <StaticImage
                    src="../images/7th/lll7_wh_flower_stand_soldout.jpg"
                    width={960}
                    alt="Life Like a Live!7 ホワイトデーフラスタ"
                    placeholder="none"
                    loading="eager"
                  />
                </a> */}
                <StaticImage
                  src="../images/7th/lll7_wh_flower_stand_soldout.jpg"
                  width={960}
                  alt="Life Like a Live!7 ホワイトデーフラスタ"
                  placeholder="none"
                  loading="eager"
                />
              </dd>
              <dd>
                {/* <a href="https://lllofficial.base.shop/" target="_blank" rel="noopener noreferrer">
                  <StaticImage
                    src="../images/7th/booth_valentine_flower_all.jpg"
                    width={960}
                    alt="Life Like a Live!7 デジタルフラスタ プラン別サイズ比較"
                    placeholder="none"
                    loading="eager"
                  />
                </a> */}
                <StaticImage
                  src="../images/7th/booth_valentine_flower_all.jpg"
                  width={960}
                  alt="Life Like a Live!7 デジタルフラスタ プラン別サイズ比較"
                  placeholder="none"
                  loading="eager"
                />
              </dd>
            </dl>
          </div>
        </div>
      </Section7thBlack>

      <SectionBlack isWhite={true} addClass="lineup_sec contain-image">
        <SectionBlackTitle enText={'LINE UP'} jaText={'ラインナップ'} />

        <div className="list_lineup" css={Styles.listLineup}>
          <div css={Styles.modalAreaStyle}>
            <div className="container">
              <ul className="row">
                {data.allMicrocmsLineup7.edges.map(({ node }, id) =>
                  node.group.map(({ name, image, url }, index) => (
                    <li className="col" key={id + '-' + index}>
                      {image ? (
                        <MemberLink
                          castName={name}
                          groupName={node.name}
                          imageUrl={image.url}
                          twitterUrl={url}
                        ></MemberLink>
                      ) : (
                        <p>{node.name + ' | ' + name}</p>
                      )}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </SectionBlack>

      <Section7thBlack addClass="twitter_sec">
        <div css={Styles.twitterBlock}>
          <SectionTitle enText={'X'} jaText={'エックス'} textWhite />
          {/* Twitter Timeline */}
          <div className="text-center">
            <a
              className="twitter-timeline"
              data-width="500"
              data-height="500"
              href="https://twitter.com/LifeLikeaLive?ref_src=twsrc%5Etfw"
            >
              Tweets by LifeLikeaLive
            </a>
          </div>
          {/* Twitter Timeline */}
        </div>

        <div css={Styles.youtubeBlock}>
          <SectionTitle enText={'YouTube'} jaText={'ユーチューブ'} textWhite />
          <a
            href="https://www.youtube.com/channel/UC_vFNa_irvWQapwwaasYMOQ"
            target="_blank"
            rel="noopener noreferrer"
            className="youtube"
          >
            <div className="image-wrap">
              <img className="platform-logo" src={svgPath.youtubeIcon} alt="YouTube" />
            </div>
          </a>
        </div>

        <div css={Styles.archiveBlock}>
          <SectionTitle enText={'Event Archives'} textWhite />

          <ul>
            <li>
              <a href="https://avex.code-avalon.com/lifelikealive/" target="_blank" rel="noopener noreferrer">
                <div className="image-wrap">
                  <StaticImage
                    src="../images/archive/1st-logo.png"
                    width={275}
                    quality={75}
                    alt="Life Like a Live!"
                    placeholder="none"
                    loading="eager"
                    formats={['webp', 'auto']}
                  />
                </div>
                <span className="archive-name">Life Like a Live!（えるすりー）</span>
              </a>
            </li>

            <li>
              <a href="/2" target="_blank">
                <div className="image-wrap">
                  <StaticImage
                    src="../images/archive/2nd-logo.png"
                    width={275}
                    quality={75}
                    alt="Life Like a Live!"
                    placeholder="none"
                    loading="eager"
                    formats={['webp', 'auto']}
                  />
                </div>
                <span className="archive-name">Life Like a Live!2（えるすりー２）</span>
              </a>
            </li>

            <li>
              <a href="/3" target="_blank">
                <div className="image-wrap">
                  <StaticImage
                    src="../images/archive/3rd-logo.png"
                    width={110}
                    quality={75}
                    alt="Life Like a Live!3"
                    placeholder="none"
                    loading="eager"
                    formats={['webp', 'auto']}
                  />
                </div>
                <span className="archive-name">Life Like a Live!3（えるすりー３）</span>
              </a>
            </li>

            <li>
              <a href="/4" target="_blank">
                <div className="image-wrap">
                  <StaticImage
                    src="../images/archive/4th-logo.png"
                    width={200}
                    quality={75}
                    alt="Life Like a Live!4"
                    placeholder="none"
                    loading="eager"
                    formats={['webp', 'auto']}
                  />
                </div>
                <span className="archive-name">Life Like a Live!4（えるすりー4）</span>
              </a>
            </li>

            <li>
              <a href="/5" target="_blank">
                <div className="image-wrap">
                  <StaticImage
                    src="../images/archive/5th-logo.png"
                    width={110}
                    quality={75}
                    alt="Life Like a Live!5"
                    placeholder="none"
                    loading="eager"
                    formats={['webp', 'auto']}
                  />
                </div>
                <span className="archive-name">Life Like a Live!5（えるすりー5）</span>
              </a>
            </li>

            <li>
              <a href="/6" target="_blank">
                <div className="image-wrap">
                  <StaticImage
                    src="../images/archive/6th-logo.png"
                    width={110}
                    quality={75}
                    alt="Life Like a Live!6"
                    placeholder="none"
                    loading="eager"
                    formats={['webp', 'auto']}
                  />
                </div>
                <span className="archive-name">Life Like a Live!6（えるすりー6）</span>
              </a>
            </li>
          </ul>
        </div>
      </Section7thBlack>

      <SectionBlack addClass="twitter_sec" isWhite={true}>
        <SectionBlackTitle enText={'CREDIT'} jaText={'クレジット'} textWhite={false} />
        <dl css={Styles.creditList}>
          <dt>主催</dt>
          <dd>バーチャル・エイベックス株式会社 / AVALON</dd>
        </dl>
        <dl css={Styles.creditList}>
          <dt>制作委員会</dt>
          <dd>Life Like a Live!制作委員会</dd>
        </dl>
        <ShareButton />
      </SectionBlack>
    </Layout7h>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allMicrocmsLineup7(sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          lineup7Id
          name
          group {
            name
            image {
              url
            }
            url
          }
        }
      }
    }
  }
`;
